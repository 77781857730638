import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import akodu from '../../assets/images/departments/fam-med/akodu.jpg';
import adejimi from '../../assets/images/departments/fam-med/adejimi.jpg';
import asiegbu from '../../assets/images/departments/fam-med/asiegbu.jpg';
import fadipe from '../../assets/images/departments/fam-med/fadipe.jpg';
import nebe from '../../assets/images/departments/fam-med/nebe.jpg';
import ogunleye from '../../assets/images/departments/fam-med/ogunleye.jpg';
import olokodana from '../../assets/images/departments/fam-med/olokodana.jpg';
import orumbie from '../../assets/images/departments/fam-med/orumbie.jpg';

const FamMed = () => {
  const services = [
    {
      name: 'Preventive care and health promotion services',
    },
    {
      name: 'Annual medical check-up',
    },
    {
      name: 'Pre-employment medical evaluation',
    },
    {
      name: 'Pre-travel health check and immunizations',
    },
    {
      name:
        'Management of acute and chronic medical conditions within the confines of Primary Care',
    },
    {
      name:
        'Minor ENT and surgical procedures (ear syringing, incision and drainage, Zadek’s procedure, suturing of wounds, wound debridement and dressing e.t.c)',
    },
    {
      name: 'Geriatric care',
    },
    {
      name: 'Mental health',
    },
    {
      name: 'Emergency care',
    },
    {
      name: 'Palliative care',
    },
  ];

  return (
    <Layout pageTitle='Department of Family Medicine | LUTH'>
      <NavOne />
      <PageHeader title='Department of Family Medicine' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Family Medicine
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        Life is multifaceted and continuous traversing from
                        cradle to the grave. This is the reason why we cannot do
                        without a Family Physician who indiscriminately deals
                        with most of the health problems that most people have,
                        most of the time, in a continuous, coordinated and
                        comprehensive manner. <br />
                        <br />
                        We offer first contact care through a team of seasoned
                        Family Physicians, Resident Doctors and Medical Officers
                        who effectively manage your health within Primary Care
                        or refer to a wealth of available specialized care, if
                        need be. <br />
                        <br />
                        Our Family Physicians provide holistic care using
                        patient-centered clinical method.
                        <br />
                        <br /> We attend to general out-patients, NHIS patients,
                        LUTH staff and students.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akodu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. BA Akodu</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Head of Department, Consultant Family Physician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACP, M.Sc,
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Emergency Medicine, Geriatric and Palliative care,
                            Primary Care and Cardiovascular Diseases
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={nebe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. JN NEBE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Family Physician, Utility Co-ordinator
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACP, FMCFM, Cert LMIH UW, Dip Ger SASGM
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Geriatric Medicine, Generational Medicine and Travel
                            Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fadipe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Y.O. Fadipe</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Family Physician, Welfare Co-ordinator
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FMCFM, Travel Medicine Cert LSHTM
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Adolescent Medicine and Travel Medicine
                          </p>
                        </div>
                      </div>                      
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olokodana} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. O.A. Olokodana-Adesalu</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Family Physician, Clinic Co-ordinator
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BSc, MBChB, Cert Project Management UW, FWACP
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Geriatric Medicine, Adolescent Medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adejimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. A.A. Adejimi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Public Physician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MPH, FWACP, Cert. Epidemiology and
                            Biostatistics
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Community Health and Primary Care, Public Health,
                            Reproductive and Family Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={orumbie} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. P.I. Orumbie</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            NHIS Co-ordinator
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MSc, FMCFM, PGD-HSE, FIIM
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Occupational Health and Safety, Infection Prevention
                            and Control, Sleep Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={asiegbu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS E.N. Asiegbu</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Departmental Head of Nursing Services Unit
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Registered Nurse/Midwife, Dip in A&E Nursing,
                            Bachelor of Nursing Science
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunleye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>SAN E.K. Ogunleye </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Head Environmental Health Services Unit
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>
                        Departmental Contact Details
                      </h2>
                      <p className='course-details__tab-text'>
                        EMAIL: familymed@luth.gov.ng
                        <br/>
                        TELEPHONE NUMBER: 07084937794 – FOR OUT-PATIENTS
                        <br />
                        CLINIC HOURS: 8AM-4PM Week Days. 10AM-2PM Weekends and
                        Public Holidays (STRICTLY FOR STAFF)
                        <br />
                        LOCATION: Third Floor, Out-Patients Clinic Building
                        <br />
                        For Clinic Bookings, call above Departmental Numbers
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default FamMed;
